<template>
    <div>
        <div class="header" id="header">
            <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/组763_1686894590711.png" class="hwlogo" @click="toindex">
            <div class="headericon" id="headericon" @click="headericon"></div>
        </div>
        <div class="headerhidden" id="headerhidden">
            <div class="headeritem" @click="toindex"> <span> 首页 </span> </div>
            <div class="headeritem" v-for="(item) in columnData" :key="item.id" @click="toLink(item.url)">
                <span>{{item.columnName}}</span>
            </div>
        </div>
        <div style="height: 1.08rem;"></div>




         



    <keep-alive>
        <router-view></router-view>
    </keep-alive>




    <Foot />


    <div class="toGrzx" @click="toGrzx">
        <img src="https://zhongyinwh.oss-cn-beijing.aliyuncs.com/scott/pic/个人中心(1)(1)_1686566476728.png" class="toGrzxImg">
    </div>



    </div>
</template>


<script>
import Foot from "@/components/foot.vue"
import Cookies from 'js-cookie'
import {getListColumn} from "@/api/index"
import store from '@/store'
export default {
    components:{
        Foot
    },
    data(){
        return {
            topnavaindex : 0,
            navtop : null,
            t:null,
            headerflag : false,
            hiddenflag : false,
            columnData:null,
        }
    },
    computed: {
        columnList() {
            return this.$store.getters.columnList
        },
    },
    methods:{
        toGrzx(){
            if(!Cookies.get('wjsToken')){
                this.$router.push({name:'dl'});
            }else{
                this.$router.push({name:'grzx'});
            }
        },
        toLink(name){
            //if(name == "yszb"){
                //window.location.href="http://www.zygart.cn"; 
            //}else{
                this.reset()
                this.$router.push({name:name});
            //}
        },
        toBack(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toyszb(){
            this.reset()
            this.$router.push({name:'yszb'});
        },
        tojrpf(){
            this.reset()
            this.$router.push({name:'jrpf'});
        },
        tohzjg(){
            this.reset()
            this.$router.push({name:'hzjg'});
        },
        tobsxw(){
            this.reset()
            this.$router.push({name:'bsxw'});
        },
        tozcfg(){
            this.reset()
            this.$router.push({name:'zcfg'});
        },
        toindex(){
            this.reset()
            this.$router.push({name:'index'});
        },
        toaboutus(){
            this.reset()
            this.$router.push({name:'gywm'});
        },
        tonews(){
            this.reset()
            this.$router.push({name:'hydt'});
        },
        tonews2(){
            this.reset()
            document.body.scrollTop = document.documentElement.scrollTop = 0
            this.$router.push({name:'news'});
        },
        togsdt(){
            this.reset()
            this.$router.push({name:'gsdt'});
        },
        toyszx(){
            this.reset()
            this.$router.push({name:'yszx'});
        },
        tohyfx(){
            this.reset()
            this.$router.push({name:'hyfx'});
        },
        tozcjd(){
            this.reset()
            this.$router.push({name:'zcjd'});
        },
        tomovielist(){
            this.reset()
            this.$router.push({name:'xmpl'});
        },
        tongyhd(){
            this.reset()
            this.$router.push({name:'gyhd'});
        },
        touserguide(){
            this.reset()
            this.$router.push({name:'userGuide'});
        },
        tocu(){
            this.reset()
            this.$router.push({name:'connectus'});
        },
        headericon(){
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            if(this.headerflag){
                headerhidden.style.top = "-100%"
                headericon.classList.remove("headericon2")
                headericon.classList.add("headericon")
                this.headerflag = false
            }else{
                headerhidden.style.top = "1.06rem"
                headericon.classList.remove("headericon")
                headericon.classList.add("headericon2")
                this.headerflag = true
            }
        },
        headjt(){
            var headjt = document.getElementById("headjt")
            var hiddenbox = document.getElementById("hiddenbox")
            if(this.hiddenflag){
                hiddenbox.style.height = "0"
                headjt.style.transform = "rotateZ(0deg)"
                this.hiddenflag = false
            }else{
                hiddenbox.style.height = "4rem"
                headjt.style.transform = "rotateZ(-180deg)"
                this.hiddenflag = true
            }
        },
        reset(){
            this.headerflag = false
            this.hiddenflag = false
            var headericon = document.getElementById("headericon")
            var headerhidden = document.getElementById("headerhidden")
            // var headjt = document.getElementById("headjt")
            // var hiddenbox = document.getElementById("hiddenbox")
            headerhidden.style.top = "-100%"
            headericon.classList.remove("headericon2")
            headericon.classList.add("headericon")
            // hiddenbox.style.height = "0"
            // headjt.style.transform = "rotateZ(0deg)"
        }
    },
    mounted(){
            

        if(this.columnList){
            console.log("store" + this.columnList)
            this.columnData = this.columnList;
            this.load && this.load.close();
        }else{
            getListColumn().then(res => {
                //console.log(res)
                this.columnData = res.result;

                store.dispatch('SetColumnList', res.result)


            })
        }

    },
}
</script>



<style lang="scss" scoped>
.serchbigbox{
    width: 100%;
    height: 0.96rem;
    padding: 0 0.22rem;
    box-sizing: border-box;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.serchbigbox .serchbox{
    width: 6.32rem;
    height: 0.56rem;
    background: #F8F8F8;
    border-radius: 3.62rem;
    overflow: hidden;
    display: flex;
    align-items: center;
}
.serchbigbox .serchbox img{
    width: 0.28rem;
    height: 0.3rem;
    margin-left: 0.2rem;
    margin-right: 0.18rem;
}
.serchbigbox .serchbox input{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #252525;
    outline: none;
    border: none;
    background: none;
}
.serchbigbox .serchbox input::-webkit-input-placeholder{
    color:#CACACA;
}
.serchbigbox .serchbox input::-moz-placeholder{
    color:#CACACA;
}
.serchbigbox .serchbox input:-moz-placeholder{
    color:#CACACA;
}
.serchbigbox .serchbox input:-ms-input-placeholder{
    color:#CACACA;
}
.serchbigbox .serchtxt{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #CACACA;
}




.movielist{
    width: 100%;
    position: relative;
    overflow: hidden;
    padding: 0 0.22rem;
}
.movieitem{
    width: 100%;
    height: 2.64rem;
    display: flex;
    margin-bottom: 0.24rem;
}
.movieitem .movieimg{
    width: 2rem;
    height: 100%;
    margin-right: 0.2rem;
    border-radius: 0.12rem;
    overflow: hidden;
}
.movieitem .movieimg img{
    width: 100%;
    height: 100%;
}
.movieitem .movietxtbox{
    width: 4.88rem;
    height: 100%;
}
.movieitem .movietxtbox .movietitle{
    font-size: 0.32rem;
    font-weight: 400;
    line-height: 0.44rem;
    color: #252525;
    margin-bottom: 0.2rem;
}
.movieitem .movietxtbox .movietxt{
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #BABABA;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin-bottom: 0.1rem;
}
.movieitem .movietxtbox .movietxt2{
    height: 0.68rem;
    font-size: 0.24rem;
    font-weight: 400;
    line-height: 0.34rem;
    color: #BABABA;
    display: flex;
    margin-bottom: 0.1rem;
}
.movieitem .movietxtbox .movietxt2 div{
    white-space: nowrap;
}
.movieitem .movietxtbox .movietxt2 p{
    display: -webkit-box;    
    -webkit-box-orient: vertical;    
    -webkit-line-clamp: 2;    
    overflow: hidden;
}
</style>